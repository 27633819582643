<template>
  <v-app>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner2">
        <Overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></Overlays>
        <v-card class="my-12">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="11">
              <div class="py-6">
                <div class="mb-6" style="font-size: 20px; font-weight: bold">
                  <span>お届け先住所を変更</span>
                </div>
                <v-spacer></v-spacer>
                <template v-if="apierror.status == 'error'">
                  <div v-for="msg of apierror.messages" :key="msg">
                    <v-row class="ml-6 mb-3 ma-3">
                      <span style="color: red">* {{ msg }} </span>
                    </v-row>
                  </div>
                </template>
                <v-spacer></v-spacer>
                <v-row align="center">
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>会社名</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="$v.form.addr_company.$model"
                      :error-messages="addr_companyErrors"
                      placeholder="会社名"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row align="center">
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>部署名</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="$v.form.addr_department.$model"
                      :error-messages="addr_departmentErrors"
                      placeholder="部署名"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>お名前</span>
                      <span
                        class="ml-1 pr-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >
                        必須</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="d-flex">
                      <v-text-field
                        v-model="$v.form.addr_sei.$model"
                        :error-messages="addr_seiErrors"
                        placeholder="姓"
                        hide-details="auto"
                        outlined
                        dense
                        color="#ff6e40"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="$v.form.addr_mei.$model"
                      :error-messages="addr_meiErrors"
                      placeholder="名"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>フリガナ</span>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="$v.form.addr_sei_kana.$model"
                      :error-messages="addr_sei_kanaErrors"
                      placeholder="セイ"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="d-flex">
                      <v-text-field
                        v-model="$v.form.addr_mei_kana.$model"
                        :error-messages="addr_mei_kanaErrors"
                        placeholder="メイ"
                        hide-details="auto"
                        outlined
                        dense
                        color="#ff6e40"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>郵便番号</span>
                      <span
                        class="ml-1 pr-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >
                        必須</span
                      >
                    </div>
                    <div style="font-size: 12px">半角-ハイフンなし</div>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="$v.form.addr_zip.$model"
                      :error-messages="addr_zipErrors"
                      outlined
                      dense
                      color="#ff6e40"
                      placeholder="1000001"
                      hide-details="auto"
                      :loading="loading"
                      @input="handleZipInput"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="d-flex justify-start"> </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3"></v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="$v.form.addr_addr1.$model"
                      :error-messages="addr_addr1Errors"
                      placeholder="市区町村番号(例：東京都千代田区大手町1-2-3)"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3"></v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="$v.form.addr_addr2.$model"
                      :error-messages="addr_addr2Errors"
                      placeholder="建物名・部屋番号"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" md="3">
                    <div>
                      <span>電話番号</span>
                      <span
                        class="ml-1 pr-1"
                        style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                      >
                        必須</span
                      >
                    </div>
                    <div style="font-size: 12px">半角-ハイフンなし</div>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      v-model="$v.form.addr_telno.$model"
                      :error-messages="addr_telnoErrors"
                      placeholder="09012345678"
                      hide-details="auto"
                      outlined
                      dense
                      color="#ff6e40"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <div class="mt-12">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="6" sm="3" class="d-flex justify-end">
                      <v-btn outlined width="100%" @click="back">
                        <span>取消</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="6" sm="3" class="d-flex justify-end">
                      <v-btn color="#ff6e40" width="100%" depressed :loading="submitStatus" @click="submit(form)">
                        <span style="color: #fff">変更</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
/* eslint-disable */
import { mapState, mapMutations, mapActions } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import { helpers } from '@vuelidate/validators'
import Overlays from '@/components/Overlays.vue'
/* eslint-disable */

const allowedKana = helpers.regex(/^[ァ-ヶー]*$/)
const allowedChar = helpers.regex(/^[0-9a-zA-Z]+[0-9a-zA-Z-_()]*$/)
const validMobile = helpers.regex(/^0[0-9]{9,10}$/)
const validZip = helpers.regex(/^[[0-9]{7}$/)
export default {
  components: {
    Overlays,
  },
  data: () => ({
    loading: false,
    submitStatus: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    form: {
      addr_compan: '',
      addr_department: '',
      addr_id: '',
      mbr_code: '',
      addr_sei: '',
      addr_mei: '',
      addr_sei_kana: '',
      addr_mei_kana: '',
      prefcode: '',
      addr_zip: '',
      addr_addr1: '',
      addr_addr2: '',
      addr_telno: '',
    },
  }),
  validations: {
    form: {
      addr_company: {
        maxLength: maxLength(50),
      },
      addr_department: {
        maxLength: maxLength(50),
      },
      addr_sei: {
        required,
        maxLength: maxLength(10),
      },
      addr_mei: {
        required,
        maxLength: maxLength(10),
      },
      addr_sei_kana: {
        // required,
        maxLength: maxLength(10),
        allowedKana,
      },
      addr_mei_kana: {
        // required,
        maxLength: maxLength(10),
        allowedKana,
      },
      addr_zip: {
        required,
        validZip,
      },
      addr_addr1: {
        required,
        maxLength: maxLength(100),
      },
      addr_addr2: {
        maxLength: maxLength(100),
      },
      addr_telno: {
        required,
        maxLength: maxLength(13),
        validMobile,
      },
    },
  },
  created() {
    this.setAbsoluteOverlayStatus(true)
    this.loadAddress(this.$route.params.id).then(() => {
      this.form = this.selectedAddress
      this.setAbsoluteOverlayStatus(false)
    })
  },
  computed: {
    ...mapState('addressStore', ['selectedAddress']),
    ...mapState('app', ['absoluteOverlayStatus']),

    addr_companyErrors() {
      const errors = []
      if (!this.$v.form.addr_company.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_company.maxLength && errors.push('最大50文字までです！')

      return errors
    },

    addr_departmentErrors() {
      const errors = []
      if (!this.$v.form.addr_department.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_department.maxLength && errors.push('最大50文字までです！')

      return errors
    },

    addr_seiErrors() {
      const errors = []
      if (!this.$v.form.addr_sei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_sei.maxLength && errors.push('最大10文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_sei.required && errors.push('必須項目')

      return errors
    },
    addr_meiErrors() {
      const errors = []
      if (!this.$v.form.addr_mei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_mei.maxLength && errors.push('最大10文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_mei.required && errors.push('必須項目')

      return errors
    },
    addr_sei_kanaErrors() {
      const errors = []
      if (!this.$v.form.addr_sei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_sei_kana.maxLength && errors.push('最大10文字までです！')

      !this.$v.form.addr_sei_kana.allowedKana && errors.push('全角カナを入力してください。')

      return errors
    },
    addr_mei_kanaErrors() {
      const errors = []
      if (!this.$v.form.addr_mei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_mei_kana.maxLength && errors.push('最大10文字までです！')

      !this.$v.form.addr_mei_kana.allowedKana && errors.push('全角カナを入力してください。')

      return errors
    },
    addr_zipErrors() {
      const errors = []
      if (!this.$v.form.addr_zip.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_zip.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_zip.validZip && errors.push('数字7桁、半角―ハイフンなしで入力して下さい。')
      return errors
    },
    addr_addr1Errors() {
      const errors = []
      if (!this.$v.form.addr_addr1.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_addr1.maxLength && errors.push('最大100文字までです。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_addr1.required && errors.push('必須項目')
      return errors
    },
    addr_addr2Errors() {
      const errors = []
      if (!this.$v.form.addr_addr2.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_addr2.maxLength && errors.push('最大100文字までです。')
      // eslint-disable-next-line no-unused-expressions
      return errors
    },
    addr_telnoErrors() {
      const errors = []
      if (!this.$v.form.addr_telno.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_telno.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.form.addr_telno.validMobile && errors.push('0から始まる10桁または11桁の番号を入力して下さい。')

      return errors
    },
  },

  methods: {
    ...mapActions('addressStore', ['loadAddress', 'editAddress']),
    ...mapMutations('app', ['setAbsoluteOverlayStatus', 'setOverlayStatus']),

    handleZipInput(val) {
      if (val.length === 7) {
        this.getAddress()
      }
    },
    getAddress() {
      const api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='
      const url = api + this.form.addr_zip
      this.clearZipError()
      this.form.prefcode = ''
      this.form.addr_addr1 = ''
      this.loading = true
      fetch(url)
        .then(response => response.json())
        .then(data => {
          if (data.status === 400) {
            // エラー時
            this.setZipError()
          } else if (data.results === null) {
            this.setZipError()
          } else {
            this.form.prefcode = data.results[0].prefcode
            this.form.addr_addr1 = data.results[0].address1 + data.results[0].address2 + data.results[0].address3
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    setZipError() {
      this.apierror.messages = ['正しい郵便番号を入力してください']
      this.apierror.status = 'error'
      this.apierror.code = 'zip'
    },
    clearZipError() {
      this.apierror.messages = ''
      this.apierror.status = ''
      this.apierror.code = ''
    },
    submit(form) {
      this.$v.$touch()
      if (this.apierror.code === 'zip') {
        return
      }
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.editAddress(form)
          .then(() => {
            this.back()
          })
          .catch(error => {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
          .finally(() => {
            this.submitStatus = false
          })
      }
    },

    back() {
      window.history.back()
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
